// 
// common.scss
//

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f6f8;
}

::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  border-radius: 10px;
}

.font-sm {
  font-size: 0.73em !important;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.font-weight-600 {
  font-weight: 600 !important;
}

.pr-10 {
  padding-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}

.h-250{
 height: 250px;
}

.bg-grey {
  background-color: #ced0d5 !important;
}

.select2-selection__menu {
  z-index: 2000 !important;
}

.MuiCard-root {
  overflow: unset !important;
}

.field-br {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: break-spaces;
}

.nav-pills .nav-link.custom-active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f6f6f6;
}

.cursor-pointer {
  cursor: pointer;
}

.image-main-panel {
  background: #ffffff;
  padding: 9px;
  border-radius: 4px;
}
.image-left-panel {
  overflow: auto;
  max-height: 375px;
  min-height: 250px;
}

.image-left-panel::-webkit-scrollbar {
  width: 12px;
}

.image-left-panel::-webkit-scrollbar-track {
  background: #f8f9fa;
}

.image-left-panel::-webkit-scrollbar-thumb {
  background-color: #ededef;
  border-radius: 20px;
  border: 3px solid #f8f9fa;
}
.panel-bordered {
  border: 1px solid #f6f6f6;
}



h1 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 72px;
  text-align: center;
  text-shadow: 0 1px 3px #555;
  color: #fff;
}

h1 small {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  display: block;
  font-size: 24px;
  color: #fff;
}

[data-preview="ToastContainer"] {
  position: relative;
}

[data-preview="ToastContainer"]::before {
  filter: blur(10px);
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  content: "";
  z-index: 0;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
}

.container {
  position: relative;
}

.github-button-container {
  margin: 20px 0 0 0;
  text-align: center;
}

.btn-container {
  text-align: center;
}

.btn-container button {
  outline: 0;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  width: 140px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 10px 5px;
  background: #1abc9c;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 3px 0px 0px #17a88c;
  transition: all 150ms ease-in;
}

.btn-container button:hover {
  background: #01a383;
  box-shadow: 0px 3px 0px 0px #008f73;
}

.prod-box {
  //border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px #ced4da, 0px 3px 4px 0px #ced4da, 0px 1px 8px 0px #ced4da;
}

.order-box {
  //border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 2px 10px;
  margin-bottom: 8px;
  box-shadow: 0px 3px 3px -2px #ced4da, 0px 3px 4px 0px #ced4da, 0px 1px 8px 0px #ced4da;
}

.footer-details {
  display: flex;
  justify-content: space-around;
}

.d-flex {
  display: flex;
}

.orderDetail-overlay{
  border-left: 1px solid #e8ecee;
  border-top: 1px solid #e8ecee;
  bottom: 0;
  box-shadow: 0 5px 20px 0 #e8ecee;
  display: flex;
  flex-direction: column;
  right: 1px;
  top: 0;
  position: fixed;
  transition: .2s ease-in;
  width: 60%;
  z-index: 1050;
  background: #f8f9fa;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .orderDetail-overlay {
    width: 100%;
  }
}

@media (max-width: 1830px) {
  .user-sales-list {
    display: none;
  }
  .user-sales-dropdown-menu {
    display: block !important;
  }
}

.user-sales-list {
  position: fixed;
  width: 250px;
  bottom: 50px;
  left: 10px;
}

.user-sales-dropdown-menu {
  display: none;
}

.top-users-sales {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //font-size: smaller;
}

@media (max-width: 1250px) {
  .top-users-sales {
    display: none;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
