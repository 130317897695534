// 
// _footer.scss
// 

.footer {
  bottom: 0;
  padding: 10px calc(#{$grid-gutter-width} / 2);
  position: fixed;
  right: 0;
  color: $footer-color;
  left: 250px;
  height: $footer-height;
  box-shadow: 0 0px 4px rgba(15, 34, 58, .12);
  background: white;
  z-index: 5;
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}